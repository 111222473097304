import React, {useEffect, useState} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Edit,
  Inject,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import { DataManager,UrlAdaptor, WebApiAdaptor } from '@syncfusion/ej2-data';

import { contextMenuItems } from "../data/dummy";
import { deviceHealthGrid } from "../data/Devices";
//import { deviceData } from "//localhost:4000/DeviceHealth";
import { Header } from "../components";

//const baseURL = 'http://localhost:8080'
//const baseURL = 'http://api.devicedash.farmhealthguardian.com'
var deviceData = new DataManager({adaptor: new WebApiAdaptor,
  url: 'https://fhg-device-dashboard-restapi.azurewebsites.net/api/getdevicehealth'
});
const Health = () => {
  const [posts, setPosts] = useState([]);
  const filterSettings = {
    type: 'CheckBox'
  };

  useEffect(() => {
     fetch(`https://fhg-device-dashboard-restapi.azurewebsites.net/api/getdevicehealth`)
        .then((response) => response.json())
        .then((data) => {
           console.log(data);
           setPosts(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
  }, []);
  return (
    <div
      className="m-2 md:m-10 p-2 md:p-10
    bg-white rounded-3xl"
    >
      <Header category="Devices" title="Health" />
      <GridComponent
        id="gridcomp"
        //dataSource={deviceData}
        dataSource={posts}
        allowPaging
        allowSorting
        allowFiltering
        filterSettings ={filterSettings}
        pageSettings={{pageSize: 20}}
        //editSettings = {{allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog'}}
        toolbar={['Search']}
        width="auto"
      >
        <ColumnsDirective>
          {deviceHealthGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[ Page, Search, Toolbar]} />
      </GridComponent>
    </div>
  );
};

export default Health;
