import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { MdOutlineCancel } from 'react-icons/md';

import { Button } from '.';
import { userProfileData } from '../data/UserProfile';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';

const UserProfile = () => {
  const { setShowUserProfile, ShowUserProfile, currentColor, auth , setAuth,setLoggedIn } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  
  // const handleMenuClick = async (e, link) => {
  //   history.push(link)
  // }

  return (
    <div className="nav-item absolute right-1 top-16 bg-gray-100 dark:bg-[#42464D] p-8 rounded-lg w-96 border-2 "  >
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl" 
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        {/* <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        /> */}
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {auth.name} </p>
          <p className="text-gray-500 text-sm dark:text-gray-300">  {auth.role}   </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {auth.email} </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div key={item}>
          <Link to={item.link}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-white cursor-pointer  dark:hover:bg-[#42464D]"
            >
            
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className=" text-xl rounded-lg p-3 hover:bg-light-gray"
              >
                {item.icon}
              </button>
              <div>
                <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
                <p className="text-gray-500 text-sm dark:text-gray-400"> {item.desc} </p>
              </div>
            </Link>
       </div>
        ))}
      </div>
      <a className="mt-5" href="./">
        <Button
          color="Green"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
        />
      </a>
    </div>

  );
//}
};

export default UserProfile;