import React,  {useEffect} from 'react';
import {AiOutlineMenu} from 'react-icons/ai';
import { FiShoppingCart } from 'react-icons/fi';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import avatar from '../data/avatar.jpg';
import {Cart, Chat, Notification, UserProfile} from '.';
import { useStateContext } from '../contexts/ContextProvider';


const NavButton = ({title, customFunc, icon, color, dotColor, currentColor}) => (
  
  <TooltipComponent content={title}
  position="BottomCenter">
    <button type="button" onClick={ customFunc}
    style ={{ color}}
    className="relative text-xl rounded-full p-3 hover: bg-light-gray"  >
      <span style={{background: dotColor}}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
      {icon}
      
    </button>
  </TooltipComponent>
)

const Navbar = () => {
  const {activeMenu, currentColor, auth, setActiveMenu, isClicked,
  setIsClicked, handleClick, ShowUserProfile,setShowUserProfile, setScreenSize, screenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);
  
  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  const handleProfileMenu = () => setShowProfile(!ShowProfile);


  return (
    <div className='flex justify-between p-2 
    md:ml-6 md:mr-6 relative'>
      <NavButton title="Menu" customFunc={()=> 
      setActiveMenu((prevActiveMenu) => 
      !prevActiveMenu)} color={currentColor} icon={<AiOutlineMenu />} />
      
       <div className="flex">
      {/* <NavButton title="Cart" 
          customFunc={()=> handleClick('cart')}
          color="blue" 
          icon={<FiShoppingCart />} 
        />
  
        <NavButton title="Chat" 
          dotColor="#03C9D7"
          customFunc={()=> handleClick('chat')}
          color="blue" 
          icon={<BsChatLeft />} 
        />
      
        <NavButton title="Notifications" 
          dotColor="#03C9D7"
          customFunc={()=> handleClick('notification')}
          color="blue" 
          icon={<RiNotification3Line />} 
        /> */}
      

        <TooltipComponent content="Profile" position='BotomCenter' >
            <div className='flex items-center
            gap-2 cursor-pointer p-1 
            hovedr:bg-light-gray rounded-lg'
            onClick={() => setShowUserProfile((prevShowProfile) => !prevShowProfile)}>
             {/* <img  
                className='rounded-full w-8 h-8'
                src={avatar}
                alt="user-profile"
             /> */}
             <p>
                <span className='text-grey-400 tex-14'>Hi, </span>{' '}
                <span className='text-grey-400 font-bold ml-1 text-14'>{auth.name}</span>
              </p>
              <MdKeyboardArrowDown className='text-grey-400 tex-14'/>
            </div>
        </TooltipComponent>
        {isClicked.cart && (<Cart />)}
        {isClicked.chat && (<Chat />)}
        {isClicked.notification && (<Notification />)}
        {ShowUserProfile && (<UserProfile />)}
      </div>
    </div>
  )
}

export default Navbar