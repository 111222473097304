import React from 'react';

export const themeColors = [
    {
        name: 'green-theme',
        color: '#00FFA3',
    },
    {
        name: 'blue-theme',
        color: '#1A97F5',
    },

    {
        name: 'purple-theme',
        color: '#7352FF',
    },
    {
        name: 'red-theme',
        color: '#FF5C8E',
    },
    {
        name: 'indigo-theme',
        color: '#1E4DB7',
    },
    {
        color: '#FB9678',
        name: 'orange-theme',
    }
];