export { };
//const baseUrl = "http://localhost:7071/api";
const baseUrl = 'https://fhg-device-dashboard-restapi.azurewebsites.net/api/GetAllDevices?code=fEHw0l9und6tfr3QESFcmcKgc5mekbE4LOU_7TFtu0mbAzFutmpSuQ=='
// get
export function getDevices() {
    //return fetch(baseUrl + "/Devices")
    return fetch(baseUrl)
        .then(res => res.json())
        .then(data => {
            return data;
        });
}

// add
export function addDevice(device) {
    console.log(device);
    return fetch(baseUrl + "/devices", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            value: order
        })
    })
        .then(data => {
            return data;
        });
}

// update
export function updateDevice(device) {
    return fetch(baseUrl + "/devices/" + device.DeviceID, {
        method: "put",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            value: order
        })
    })
        .then(data => {
            return data;
        });
}

// delete
export function deleteDevice(primaryKey) {
    return fetch(baseUrl + "/devices/" + primaryKey, {
        method: "delete"
    })
        .then(data => {
            return data;
        });
}