import React, {useEffect, useState} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Edit,
  EditSettingsModel,
  Inject,
  Toolbar,
  ToolbarItems
} from "@syncfusion/ej2-react-grids";
import { DataManager,UrlAdaptor, WebApiAdaptor, RemoteSaveAdaptor } from '@syncfusion/ej2-data';
import { getDevices, addDevice, updateDevice, deleteDevice } from '../components/CatalogService';

import { contextMenuItems } from "../data/dummy";
import { devicesGrid } from "../data/Devices";
//import { deviceData } from "//localhost:4000/devices";
import { Header } from "../components";

// const baseURL = 'http://localhost:7071/api/GetAllDevices'
// //const baseURL = 'https://fhg-device-dashboard-restapi.azurewebsites.net/api/GetAllDevices?code=fEHw0l9und6tfr3QESFcmcKgc5mekbE4LOU_7TFtu0mbAzFutmpSuQ=='
// // var deviceData = new DataManager({
// //   url: baseURL 
// // });
// // const deviceData = new DataManager({
// //   adaptor: new UrlAdaptor,
// //   url: 'http://localhost:7071/api/GetAllDevices',
// //   updateUrl: 'http://localhost:7071/api/CrudDevices',
// //        offline: true 
// // });


//  const actionBegin2 = (args) => {
//   if (args.requestType === 'add') {
//        this.setState({ isEditing: true });
//    }
//    if (args.requestType === 'refresh') {
    
//    }

//   if (args.requestType === 'beginEdit') {
//       this.setState({ isEditing: true });
//   }

//   if (args.requestType === 'cancel') {
//       this.setState({ isEditing: false });
//   }

//   if (args.requestType === 'save') {
//       if (this.gridInstance.pageSettings.currentPage !== 1 && this.gridInstance.editSettings.newRowPosition === 'Top') {
//           args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - this.gridInstance.pageSettings.pageSize;
//       }
//       else if (this.gridInstance.editSettings.newRowPosition === 'Bottom') {
//           args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - 1;
//       }

//       this.setState({ isEditing: false });
//   }
//  }


const Catalog = () => {
  // const [posts, setPosts] = useState([]);
  // useEffect(() => {
  //    fetch(baseURL)
  //       .then((response) => response.json())
  //       .then((data) => {
  //          console.log(data);
  //          setPosts(data);
  //       })
  //       .catch((err) => {
  //          console.log(err.message);
  //       });
  // }, []);

  //const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true , mode: 'Dialog'};
  const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];

  const [data, setData] = useState();
  useEffect(() => {
    refreshGrid();
  }, []);

  function refreshGrid() {
    getDevices()
      .then(
        data => {
          setData(data);
        }
      );
  }

  function dataSourceChanged(state) {
    if (state.action === "add") {
      addDevice(state.data)
        .then(res => refreshGrid());
    } else if (state.action === "edit") {
      updateDevice(state.data)
        .then(res => refreshGrid());
    } else if (state.requestType === "delete") {
      deleteDevice(state.data[0].DeviceID)
        .then(res => refreshGrid());
    }
  }

  
  return (
    <div
      className="m-2 md:m-10 p-2 md:p-10
    bg-white rounded-3xl"
    >
      <Header category="Devices" title="Catalog" />
      <GridComponent
        //id="gridcomp"
        //dataSource={deviceData}
        //dataSource={posts}
        dataSource={data}
        allowPaging
        allowSorting
        allowFiltering
        pageSettings={{pageSize: 30}}
        editSettings = {{allowEditing: true, allowAdding: true, allowDeleting: true, mode: "Normal"}}
        toolbar={['Search','Add', 'Edit', 'Delete', 'Update', 'Cancel']}
        //actionBegin= {actionBegin2.bind(this)}
        dataSourceChanged={dataSourceChanged}
        //width="auto"
      >
        <ColumnsDirective>
          {devicesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Edit, Page, Search, Toolbar]} />
      </GridComponent>
    </div>
  );
};

export default Catalog;
