import React from 'react';
import { BsKanban, BsBarChart, BsFillExclamationTriangleFill, BsCurrencyDollar, BsShield, BsChatLeft } from 'react-icons/bs';
import { FiSettings, FiPieChart, FiPercent, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';

export const userProfileData = [
    {
        icon: <FiSettings />,
        title: 'My Profile',
        desc: 'Account Settings',
        iconColor: '#03C9D7',
        iconBg: '#E5FAFB',
        link: "./ChangePassword"
    },
    // {
    //     icon: <BsShield />,
    //     title: 'My Inbox',
    //     desc: 'Messages & Emails',
    //     iconColor: 'rgb(0, 194, 146)',
    //     iconBg: 'rgb(235, 250, 242)',
    // },
    // {
    //     icon: <FiCreditCard />,
    //     title: 'My Tasks',
    //     desc: 'To-do and Daily Tasks',
    //     iconColor: 'rgb(255, 244, 229)',
    //     iconBg: 'rgb(254, 201, 15)',
    // },
];