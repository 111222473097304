import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { RequireAuth, Navbar, Footer, Sidebar, ThemeSettings, DashboardLayout  } from "."
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import {
  Status, Orders, Calendar, Suppliers, Catalog, Health, Stacked, Pyramid,
  Customers, Kanban, Area, Bar, Pie, Line, Financial, ColorPicker,
  ColorMapping, Editor, IssueHistory,
} from '../pages';

const Dashboard = () => {
    const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings, loggedIn, setLoggedIn } = useStateContext();
    return (
        <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
              <TooltipComponent content="Settings" position="Top">
                <button type='button'
                  className='text-3xl p-3 hover:drop-shadow-xl hover:bg-light-grey text-white'
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: '50%' }}>
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className='w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white'>
                <Sidebar />
              </div>
            ) : (
              <div className='w-0 dark:bg-secondary-dark-bg'>
                <Sidebar />
              </div>
            )}
            <div className={`dark:bg-main-bg bg-main-bg min-h-screen w-full 
                ${activeMenu ? 'md:ml-72' : 'flex-2'}`
            }>

                <div className='fixed md:static bg-main-bg dark:gb-main-dark-bg navbar w-full'>
                    <Navbar />
                </div>

                <div>
                    {themeSettings && (<ThemeSettings />)}

                    <Outlet />
                </div>
            </div>
          </div>
    );
}

export default Dashboard;