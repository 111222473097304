import React, {useEffect, useState} from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { Stacked, Pie, Button, SparkLine } from "../components";
import {  BsFillExclamationTriangleFill } from 'react-icons/bs';
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Search,
  Toolbar,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

import { ordersData, contextMenuItems } from "../data/dummy";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { deviceHealthGrid } from "../data/Devices";

const Status = () => {

  const [posts, setPosts] = useState([]);
  const [health, setHealth] = useState([]);
  const filterSettings = {
    type: 'CheckBox'
};


  useEffect(() => {
     fetch(`https://fhg-device-dashboard-restapi.azurewebsites.net/api/getCurrentIssues`)
     //fetch(`http://localhost:7071/api/GetCurrentIssues`)
        .then((response) => response.json())
        .then((data) => {
           console.log(data);
           setPosts(data);
        })
        .catch((err) => {
           console.log(err.message);
        });

    //fetch(`http://localhost:7071/api/GetCurrentIssuesByType`)
    fetch(`https://fhg-device-dashboard-restapi.azurewebsites.net/api/GetCurrentIssuesByType`)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      // var pieData = [];
      // for (let index = 0; index < data.length; index++) {
      //   const h = data[index];
      //   pieData.push({x: h.key, y:h.value, text:h.value})
      // }
      // setHealth(pieData);
      // console.log(pieData);
      setHealth(data);
      })
      .catch((err) => {
        console.log(err.message);
      });  
    
}, []);

  const filterOptions = {
    type: 'Excel'
  };
  
  return (
    <div>
      <p className="flex items-center justify-center font-bold text-gray-600"></p>
      <div className="mt-12 flex gap-10 flex-wrap justify-center">      
        <div className="flex flex-wrap lg:flex-nowrap justifiy-center">
        
          {/* ------------------Start of Earnings Block -------------------------------------- */}
          <div
            className="bg-white
            dark:text-gray-200
            dark:bg-secondary-dark-bg h-34
            rounded-xl w-full  lg:w-400 p-5 pt-5 m-1
             bg-no-repeat bg-cover
            bg-center"
          >
            <div className="flex justify-between items-center">
              <div>
                <span className={`items-center gap-3 ml-3 mt-2 flex font-extrabold 
          tracking-tight text-5xl text-amber-500`}>
                  <BsFillExclamationTriangleFill /><span className={'text-3xl text-gray-700 font-extrabold'}>{posts.length}  Current Issues</span>
                </span>
              </div>
            </div>
            
            {/* <div className="mt-6">
              <Button
                color="white"
                bgColor="blue"
                text="Download"
                borderRadius="10px"
                size="md"
              />
            </div> */}
          </div>
          {/* ------------------ End of Earnings Block -------------------------------------- */}
          <div className="bg-white
            dark:text-gray-200
            dark:bg-secondary-dark-bg h-34
            rounded-xl w-auto p-2 pt-2
             bg-no-repeat bg-cover
            bg-center">
                <Pie id="pie-chart" name="Health Breakdown" data={health}
                  legendVisiblity={true} height="160px" />
              
          </div>
          {/* ------------------ Start of Cards block --------------------------------------- */}
          {/* <div className="flex m-3 flex-wrap items-center justify-center gap-4">Dummy data
          
            
              {earningData.map((item) => (
                <div
                  key={item.title}
                  className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56
                p-4 pt-9 rounded-2xl"
                >
                  <button
                    type="button"
                    style={{
                      color: item.iconColor,
                      backgroundColor: item.iconbg,
                    }}
                    className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
                  >
                    {item.icon}
                  </button>
                  <p className="mt-3">
                    <span className="text-lg font-semibold">{item.amount}</span>
                    <span className={`text-sm text-${item.pcColor} ml-2`}>
                      {item.percentage}
                    </span>
                  </p>
                  <p className="text-sm text-grey-400 mt-1">{item.title}</p>
                </div>
              ))}

          </div> */}
          {/* ------------------ End of Cards block ----------------------------------------- */}
        </div>
      </div>
     <div className="m-2 md:m-10 p-2 md:p-10
    bg-white rounded-3xl text-sm"
    >
      <Header title="Current Issues" />
      <GridComponent
        id="gridcomp"
        //dataSource={deviceData}
        dataSource={posts}
        allowPaging={true}
        allowResizing={true}
        allowSorting={true}
        allowFiltering={true}
        filterSettings = {filterOptions}
        pageSettings={{pageSize: 20}}
        //editSettings = {{allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog'}}
        toolbar={['Search']}
        //width="auto"
      >
        <ColumnsDirective>
          {deviceHealthGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize,  Page, Search, Filter, Toolbar]} />
      </GridComponent>
    </div>
    </div>
  );
};

export default Status;
