
import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

import Axios from 'axios';

const ChangePassword = () => {

    const { currentColor, setMode, setThemeSettings, setAuth, auth, loggedIn, setLoggedIn } = useStateContext();

    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    //const {setAuth} = useContext()
    //const LOGIN_URL = "http://localhost:7071/api/ChangePassword"
    const LOGIN_URL = "https://fhg-device-dashboard-restapi.azurewebsites.net/api/ChangePassword"
    const userRef = useRef();
    const errRef = useRef();
    const msgRef = useRef()

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [newPwd, setNewPwd] = useState('');
    const [oldPwd, setOldPwd] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [Msg, setMsg] = useState('');

    
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);


    useEffect(() => {
        setValidPwd(PWD_REGEX.test(newPwd));
        setValidMatch(newPwd === matchPwd);
    }, [newPwd, matchPwd])
    
    // useEffect(() => {6
    //     userRef.current.focus();
    // }, []);

    useEffect(() => {
        errRef.current.focus();
    }, [oldPwd, newPwd, matchPwd]);

    useEffect(() => {
        msgRef.current.focus();
    }, []);

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if ((oldPwd == auth.pwd) && (validMatch))
        {
        try {
            var user = auth.user;
            var pwd = auth.pwd;
            const responce = await Axios.post(LOGIN_URL, JSON.stringify({ user, pwd, newPwd }),
                {
                    headers: { 'Contect-Type': 'application/json' },
                    //withCredentials: true
                }
            );

            setMsg("Password Change Successiful");


        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }
    }

    return (
        <div
      className="m-2 md:m-10 p-2 md:p-10
    bg-white rounded-3xl"
    >
                 <div >
                    <h1 className={'text-3xl text-gray-700 font-extrabold '} >Account Settings</h1>
                    <section className={'nav-item top-16 bg-gray-200 dark:bg-[#42464D] p-4 rounded-3xl w-1/3 border-2'}>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <p ref={msgRef} className={Msg ? "msg" : "offscreen"} aria-live="assertive">{Msg}</p>
                        <h1 className={'text-3xl text-gray-700 font-extrabold'} >Change Password</h1>
                        <form className="pt-2 space-y-4" onSubmit={handleChangePassword}>
                        <label className="" htmlFor='oldPwd'>Old Password:</label>
                            <input
                            className=""
                                type="password"
                                id="oldPwd"
                                onChange={(e) => setOldPwd(e.target.value)}
                                value={oldPwd}
                                required
                            />
                            <br />
                            <label className="" htmlFor='newPwd'>New Password:</label>
                            <input
                            className="place-items-end "
                                type="password"
                                id="newPwd"
                                onChange={(e) => setNewPwd(e.target.value)}
                                value={newPwd}
                                required
                            />
                            <br />
                            <label className="" htmlFor='matchPwd'>Confirm Password:</label>
                            <input
                            className=""
                                type="password"
                                id="matchPwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                            />
                            <br />
                            <button className='text-black border rounded-xl bg-green-300 h-16 w-60' style={{ backgroundColor: currentColor }}> Change Password</button>
                            {/* <Button
                            color="Black"
                            bgColor={currentColor}
                            text="Change Password"
                            borderRadius="10px"
                            width="full"
                            /> */}
                            
                        </form>

                    </section >
                </div>
                </div>
    )
}


export default ChangePassword