import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Helmet } from 'react-helmet';
import { RequireAuth, Navbar, Footer, Sidebar, ThemeSettings, DashboardLayout } from './components';
import {
  Status, Orders, Calendar, Suppliers, Catalog, Health, Stacked, Pyramid,
  Customers, Kanban, Area, Bar, Pie, Line, Financial, ColorPicker,
  ColorMapping, Editor, IssueHistory, Missing, Login, ChangePassword
} from './pages';

import { useStateContext } from './contexts/ContextProvider';

import './App.css';



const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings, loggedIn, setLoggedIn } = useStateContext();


  return (
    <div>
      <Helmet>
        <title>Device Dashoard</title>
        <meta name='description' content='Dashboard for Momitoring FHG Devices'></meta>
      </Helmet>
      <BrowserRouter>

        <Routes>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="Unauthorized" element={<Login />} />
          <Route path="Missing" element={<Login />} />

          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route element={<DashboardLayout />}>

              {/* Dashboard */}
              <Route path="/" element={<Status />} />
              <Route path="/Status" element={<Status />} />

              {/* Devices */}
              <Route path="/Catalog" element={<Catalog />} />
              <Route path="/Health" element={<Health />} />
              <Route path="/Orders" element={<Orders />} />
              <Route path="/IssueHistory" element={<IssueHistory />} />

              <Route path="/ChangePassword" element={<ChangePassword />} />

              {/* Contacts */}

              <Route path="/Customers" element={<Customers />} />
              <Route path="/Suppliers" element={<Suppliers />} />
              {/* Apps */}
              <Route path="/kanban" element={<Kanban />} />
              <Route path="/editor" element={<Editor />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/color-picker" element={<ColorPicker />} />

              {/* Charts */}
              <Route path="/line" element={<Line />} />
              <Route path="/area" element={<Area />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/financial" element={<Financial />} />
              <Route path="/color-mapping" element={<ColorMapping />} />
              <Route path="/pyramid" element={<Pyramid />} />
              <Route path="/stacked" element={<Stacked />} />


            </Route>

          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Routes>

      </BrowserRouter >
    </div >

  )
}

export default App