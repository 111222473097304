import React, {useEffect, useState} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Edit,
  Inject,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import { DataManager,UrlAdaptor, WebApiAdaptor } from '@syncfusion/ej2-data';
import '../App.css';

import { contextMenuItems } from "../data/dummy";
import { OrdersGrid } from "../data/Devices";
//import { deviceData } from "//localhost:4000/devices";
import { Header } from "../components";

//const baseURL = 'http://localhost:8080'
const baseURL = 'http://api.devicedash.farmhealthguardian.com'
var deviceData = new DataManager({adaptor: new WebApiAdaptor,
  url: baseURL + '/Orders/',
  insertUrl: baseURL + '/Orders/',
  removeUrl: baseURL + '/Orders/',
  updateUrl: baseURL + '/Orders/'
});



const Orders = () => {


  function setHeaderHeight() {
    /** Obtain the width of the headerText content */
    const textWidth = document.querySelector(".orientationcss > div").scrollWidth;
    const headerCell = document.querySelectorAll(".e-headercell");
    for (let i = 0; i < headerCell.length; i++) {
        /** Assign the obtained textWidth as the height of the headerCell */
        (headerCell.item(i)).style.height = textWidth + 'px';
    }
};

  const [posts, setPosts] = useState([]);
  useEffect(() => {
     fetch(baseURL + '/Orders')
        .then((response) => response.json())
        .then((data) => {
           console.log(data);
           setPosts(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
  }, []);
  return (
    //this.setHeaderHeight = this.setHeaderHeight.bind(this),
    <div
      className="m-2 md:m-10 p-2 md:p-5
    bg-white rounded-3xl"
    >
      <Header category="Devices" title="Orders" />
      <GridComponent
      // created={
      //   //const textWidth = document.querySelector(".orientationcss > div").scrollWidth;
      //   var headerCell = {document.querySelectorAll(".e-headercell")}
      //   for (let i = 0; i < headerCell.length; i++) {
      //       /** Assign the obtained textWidth as the height of the headerCell */
      //       (headerCell.item(i)).style.height = textWidth + 'px';
      //   }
      // }
        //created={this.setHeaderHeight}
        id="gridcomp"
        //dataSource={deviceData}
        dataSource={posts}
        allowPaging
        allowSorting
        pageSettings={{pageSize: 20}}
        editSettings = {{allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog'}}
        toolbar={['Search','Add', 'Edit', 'Delete', 'Update', 'Cancel']}
        width="80%"
      >
        <ColumnsDirective>
          {OrdersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} 
                //...headerCell.item.style.height = '150'
               />
          ))}
        </ColumnsDirective>
        <Inject services={[Edit, Page, Search, Toolbar]} />
      </GridComponent>
    </div>
  );
};

export default Orders;
