import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
};

export const ContextProvider = ({ children }) => {


    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);

    const [currentColor, setCurrentColor] = useState('#00ffa3');
    const [currentMode, setCurrentMode] = useState('Light');
    const [themeSettings, setThemeSettings] = useState(false);

    const [ShowUserProfile, setShowUserProfile] = useState(false);

    const [auth, setAuth] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    // const [UserName, setUserName] = useState("KWright");
    // const [name, setName] = useState("Keegan Wright");
    // const [UserEmail, setUserEmail] = useState("kwright@farmhealthguardian.com");
    // const [UserRole, setUserRole] = useState("Administrator");

    const setMode = (e) => {
        setCurrentMode(e.target.value);
        localStorage.setItem('themeMode', e.target.value);
    };

    const setColor = (color) => {
        setCurrentColor(color);
        localStorage.setItem('colorMode', color);
    };

    const handleClick = (clicked) =>
        setIsClicked({ ...initialState, [clicked]: true });


    return (
        <StateContext.Provider
            value={{
                currentColor,
                currentMode,
                setCurrentColor,
                setCurrentMode,
                setMode,
                setColor,
                themeSettings,
                setThemeSettings,

                activeMenu,
                setActiveMenu,
                isClicked,
                setIsClicked,
                handleClick,
                screenSize,
                setScreenSize,

                ShowUserProfile,
                setShowUserProfile,

                auth, setAuth,
                loggedIn, setLoggedIn
                // name,
                // setName,
                // UserName,
                // setUserName,
                // UserRole,
                // setUserRole,
                // UserEmail,
                // setUserEmail,
            }}>
            {children}
        </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);