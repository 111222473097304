
import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";

import { useStateContext } from '../contexts/ContextProvider';

import Axios from 'axios';

const Login = () => {

    const { setColor, setMode, setThemeSettings, setAuth, auth, loggedIn, setLoggedIn } = useStateContext();


    //const {setAuth} = useContext()
    //const LOGIN_URL = "http://localhost:7071/api/Auth"
    const LOGIN_URL = "https://fhg-device-dashboard-restapi.azurewebsites.net/api/auth"
    const userRef = useRef();
    const errRef = useRef()

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    

    useEffect(() => {
       
        userRef.current.focus();
    }, []);

    useEffect(() => {
   
        errRef.current.focus();
    }, [user, pwd]);


    const handleSumbit = async (e) => {
        e.preventDefault();
        try {
            const responce = await Axios.post(LOGIN_URL, JSON.stringify({ user, pwd }),
                {
                    headers: { 'Contect-Type': 'application/json' },
                    //withCredentials: true
                }
            );
            console.log(responce.data);
            if (responce.data) {
                var res = responce.data;
                if (res.auth) {
                    //set User info
                    const accessToken = res?.accessToken;
                    const role = res?.role;
                    const email = res?.email;
                    const name = res.name;
                    const user = res.userName;
                    const options = res.options
                    setAuth({ user, name, pwd, role, email, accessToken, options});

                    //set User preferances
                    // if (res.options) {
                    //     var o = res.options;
                    //     setColor(o.color);
                    //     setMode(o.mode);
                    // }

                    setUser('');
                    setPwd('');
                    setLoggedIn(true);
                    res = "";
                    navigate(from, { replace: true });
                    //setAuth(true);
                }
                else
                {
                    setErrMsg('Unauthorized');
                }
            }

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }

    }

    return (
        <>
            {loggedIn ? (
                <section className={'.login_section'}>
                    <h1>You are Logged in!</h1>
                    <br />
                    <Link to="./">
                        Go to homepage
                    </Link>
                </section>
            ) : (
                 <div className={"loginMain"}>
            
                    <h1 className="text-5xl text-green-300 align-top font-extrabold justify-center">Device Dashboard</h1>
                    <section className={"loginSection"}>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <h1 className="text-3xl">Sign In</h1>
                        <form className={'loginForm'} onSubmit={handleSumbit}>
                            <label className={"l"} htmlFor='username'>Username:</label>
                            <input
                            className={'loginInput'}
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />
                            <label className={"l"} htmlFor='password'>Password:</label>
                            <input
                            className={'loginInput'}
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <button className={"b"}>Sign In</button>
                        </form>

                    </section >
                    <br />
                    <br />
                </div>
            )}
        </>
    )
}


export default Login