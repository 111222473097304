import React from 'react';
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineExclamation, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiEdit, FiPieChart, FiPercent, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';
import { BsKanban, BsBarChart, BsFillExclamationTriangleFill, BsCurrencyDollar, BsShield, BsChatLeft } from 'react-icons/bs';
import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { MdOutlineSupervisorAccount, MdGpsFixed } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import { GiLouvrePyramid } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';
import { FaHeartbeat } from 'react-icons/fa';
import { SiSpeedtest } from 'react-icons/si';

export const links = [
    {
        title: 'Dashboards',
        links: [
            {
                name: 'Status',
                icon: <SiSpeedtest />,
            },
        ],
    },

    {
        title: 'Devices',
        links: [
            {
                name: 'Catalog',
                icon: <MdGpsFixed />,
            },
            {
                name: 'Health',
                icon: <FaHeartbeat />,
            },
            // {
            //   name: 'Orders',
            //   icon: <AiOutlineShoppingCart />,
            // },

            // {
            //     name: 'IssueHistory',
            //     icon: <BsFillExclamationTriangleFill />,
            // }
        ],
    }
    //{
    //   title: 'Contacts',
    //   links: [
    //     {
    //       name: 'customers',
    //       icon: <RiContactsLine />,
    //     },
    //     {
    //       name: 'Suppliers',
    //       icon: <IoMdContacts />,
    //     },

    //   ],
    // },
    // {
    //   title: 'Other',
    //   links: [
    //     {
    //       name: 'calendar',
    //       icon: <AiOutlineCalendar />,
    //     },
    //     {
    //       name: 'kanban',
    //       icon: <BsKanban />,
    //     },
    //     {
    //       name: 'editor',
    //       icon: <FiEdit />,
    //     },
    //     {
    //       name: 'color-picker',
    //       icon: <BiColorFill />,
    //     },
    //     {
    //       name: 'line',
    //       icon: <AiOutlineStock />,
    //     },
    //     {
    //       name: 'area',
    //       icon: <AiOutlineAreaChart />,
    //     },

    //     {
    //       name: 'bar',
    //       icon: <AiOutlineBarChart />,
    //     },
    //     {
    //       name: 'pie',
    //       icon: <FiPieChart />,
    //     },
    //     {
    //       name: 'financial',
    //       icon: <RiStockLine />,
    //     },
    //     {
    //       name: 'color-mapping',
    //       icon: <BsBarChart />,
    //     },
    //     {
    //       name: 'pyramid',
    //       icon: <GiLouvrePyramid />,
    //     },
    //     {
    //       name: 'stacked',
    //       icon: <AiOutlineBarChart />,
    //     },
    //   ],
    // },
];

export const devicesGrid = [
    // {
    //   headerText: 'Device',
    //   width: '150',
    //   template: gridEmployeeProfile,
    //   textAlign: 'Center'
    // },
    {
        field: 'Status',
        headerText: 'Status',
        width: '170',
        textAlign: 'Left',
    },
    {
        field: 'Internal Code',
        headerText: 'Internal ID',
        width: '170',
        textAlign: 'Left',

    },
    {
        field: 'Client',
        headerText: 'Customer',
        width: '170',
        textAlign: 'Left',
    },
    {
        field: 'Location Assigned',
        headerText: 'Name',
        width: '170',
        textAlign: 'Left',
    },
    {
        field: 'Device Type',
        headerText: 'Device Type',
        width: '170',
        textAlign: 'Left',
    }

    // {
    //   headerText: 'Country',
    //   width: '120',
    //   textAlign: 'Center',
    //   template: gridEmployeeCountry
    // },

    // {
    //   field: 'serial',
    //   headerText: 'Serial',
    //   width: '135',
    //   textAlign: 'Left'
    // },
    // {
    //   field: 'supplier',
    //   headerText: 'Supplier',
    //   width: '120',
    //   textAlign: 'Left'
    // },
    // {
    //   field: 'battery',
    //   headerText: 'Battery',
    //   width: '125',
    //   textAlign: 'Center'
    // },
    // {
    //   field: 'location',
    //   headerText: 'Location',
    //   width: '125',
    //   textAlign: 'Left'
    // },
    // {
    //   field: 'timestamp',
    //   headerText: 'Timestamp',
    //   width: '125',
    //   textAlign: 'Left'
    // },
];
export const deviceHealthGrid = [
    // {
    //   headerText: 'Device',
    //   width: '150',
    //   template: gridEmployeeProfile,
    //   textAlign: 'Center'
    // },
    {
        field: 'client',
        headerText: 'Client',
        width: '170',
        textAlign: 'Left',
    },
    {
        field: 'id',
        headerText: 'ID',
        width: '0',
        textAlign: 'Left',
    },
    {
        field: 'name',
        headerText: 'Name',
        width: '170',
        textAlign: 'Left',
    },
    {
        field: 'internalCode',
        headerText: 'Internal Code',
        width: '150',
        textAlign: 'Left'
    },
    {
        field: 'serial',
        headerText: 'Serial',
        width: '150',
        textAlign: 'Left'
    },
    {
        field: 'supplier',
        headerText: 'Supplier',
        width: '100',
        textAlign: 'Left'
    },
    {
        field: 'model',
        headerText: 'Model',
        width: '85',
        textAlign: 'Left',
    },

    {
        field: 'health',
        headerText: 'Health',
        width: '100',
        textAlign: 'Center'
    },
    {
        field: 'rawHealth',
        headerText: 'Raw Health',
        width: '150',
        textAlign: 'Center'
    },

    {
        field: 'timestamp',
        headerText: 'Timestamp',
        width: '200',
        textAlign: 'Left'
    },
    {
        field: 'location',
        headerText: 'Location',
        width: '200',
        textAlign: 'Left'
    },
];

export const OrdersGrid = [
    // {
    //   headerText: 'Device',
    //   width: '150',
    //   template: gridEmployeeProfile,
    //   textAlign: 'Center'
    // },
    {
        field: 'Status',
        headerText: 'Status',
        width: '100',
        textAlign: 'Left',
    },
    {
        field: 'Purchase Order',
        headerText: 'PO#',
        width: '175',
        textAlign: 'Left',
    },

    {
        field: 'Supplier',
        headerText: 'Supplier',
        width: '125',
        textAlign: 'Left'
    },
    {
        field: 'Order date',
        headerText: 'Order Date',
        width: '125',
        textAlign: 'Left'
    },
    {
        field: 'Received',
        headerText: 'Received',
        width: '125',
        textAlign: 'Left',
    },

    {
        field: 'Added to System',
        headerText: 'Added to System',
        width: '125',
        textAlign: 'Left'
    },

    {
        field: 'Tracking #',
        headerText: 'Tracking #',
        width: '175',
        textAlign: 'Left'
    },
    {
        field: 'BeTen NA',
        headerText: 'BeTen NA',
        width: '150',
        textAlign: 'Center',

        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'BeTen EU',
        headerText: 'BeTen EU',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }

    },
    {
        field: 'BeSol NA',
        headerText: 'BeSol NA',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'BeSol EU',
        headerText: 'BeSol EU',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'BeWhere BLE01 WiFi Gateway',
        headerText: 'BLE Gateway',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'BeWhere BTB-04 BLE Beacon',
        headerText: 'BLE Beacon BTB-4',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'BeWhere BTB-05 BLE Beacon',
        headerText: 'BLE Beacon BTB-5',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Samsara Vehicle Gateway',
        headerText: 'Samsara Vehicle Gateway',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Samsara Unpowered',
        headerText: 'Passport S',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Samsara Unpowered EU',
        headerText: 'Passport S EU',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Azuga OBD2',
        headerText: 'Passport A',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Rivercity Gateway',
        headerText: 'Rivercity Gateway',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Rivercity Oyster',
        headerText: 'Rivercity Oyster',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Rivercity Guppy',
        headerText: 'Rivercity Guppy',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Minew BLE gateway',
        headerText: 'Minew BLE gateway',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Minew C10 Beacon',
        headerText: 'Minew C10 Beacon',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Minew C8 Tag',
        headerText: 'Minew C8 Tag',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'Minew P1 Robust Beacon',
        headerText: 'Minew P1 Robust Beacon',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
    {
        field: 'AnyTrek',
        headerText: 'Passport L',
        width: '165',
        textAlign: 'Center',
        customAttributes: { class: 'orientationcss' }
    },
];